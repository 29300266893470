<button
  mat-icon-button
  (click)="openFrillFeedback()"
  class="mat-icon-button-flex"
  [disabled]="giftLoading$ | async"
  [matBadge]="giftBadgeCount$ | async"
  [matBadgeHidden]="!(giftBadgeCount$ | async)"
  matBadgeOverlap
  matBadgeColor="warn"
>
  <mat-icon *ngIf="!(giftLoading$ | async); else widgetLoadingRef" svgIcon="gift"></mat-icon>
</button>
<ng-template #widgetLoadingRef>
  <mat-progress-spinner [strokeWidth]="2" [diameter]="18" mode="indeterminate"> </mat-progress-spinner>
</ng-template>
