import {
  Directive,
  Renderer2,
  ElementRef,
  TemplateRef,
  ViewContainerRef,
  Input,
  OnInit,
  OnChanges,
} from '@angular/core';
import { AppService } from '@app/app.service';
import { isTeamsWindow } from '@env/msal';

declare const window: Window;

@Directive({
  selector: '[teams-hide]',
})
export class TeamsUiConditionDirective implements OnInit {
  constructor(private viewContainer: ViewContainerRef, private elRef: ElementRef, private appService: AppService) {}
  @Input('teams-hide')
  teamsHide: boolean = true;
  @Input('teamsHideMobile')
  teamsHideMobile?: boolean;
  ngOnInit() {
    if (
      this.viewContainer?.element && this.teamsHide !== false
        ? !!window.teams || !!window['teams_test'] || isTeamsWindow()
        : !(!!window.teams || !!window['teams_test'] || isTeamsWindow())
    ) {
      if (this.teamsHideMobile === undefined || (this.teamsHideMobile === true && window.teamsMobile)) {
        this.viewContainer.clear();
        this.viewContainer.element.nativeElement?.remove();
      }
    }
  }
}
