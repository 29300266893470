import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { FrillButtonService } from './frill-button.service';

@Component({
  selector: 'tg-frill-button',
  templateUrl: './frill-button.component.html',
  styleUrls: ['./frill-button.component.scss'],
})
export class FrillButtonComponent implements OnInit {
  constructor(private frillService: FrillButtonService) {}
  ngOnInit(): void {}
  giftLoading$ = this.frillService.frillLoading.asObservable();
  giftBadgeCount$ = this.frillService.frillBadgeCount.asObservable().pipe(map((x) => x?.clamp?.(0, 9)));
  async openFrillFeedback() {
    await this.frillService.getFrillWidget().then((frill) => {
      frill.toggle();
    });
  }
}
