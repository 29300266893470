import { OperatorFunction, SchedulerLike, concat } from 'rxjs';
import { debounceTime, publish, take } from 'rxjs/operators';

export function debounceTimeAfter<T>(
  amount: number,
  dueTime: number,
  scheduler?: SchedulerLike
): OperatorFunction<T, T> {
  return publish((value) => concat(value.pipe(take(amount)), value.pipe(debounceTime(dueTime, scheduler))));
}

export function debounceTimeAfterFirst<T>(dueTime: number, scheduler?: SchedulerLike): OperatorFunction<T, T> {
  return debounceTimeAfter<T>(1, dueTime, scheduler);
}
