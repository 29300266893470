import { Injectable, Inject, OnInit, Injector, ComponentRef, ElementRef } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { CalendarRangePickerOverlayRef } from './calendar-range-picker-ref';
import { CalendarRangePickerComponent } from './calendar-range-picker.component';
import { CALENDAR_RANGE_DIALOG_DATA } from './calendar-range-picker-ref-tokens';
import { TRangePresets, TIME_RANGE_DEFAULT_RANGES, TRange } from '../time-range-constants';
import { addMonths, endOfDay, subMonths } from 'date-fns/esm';

export interface CalendarRangeConfig {
  presets?: TRangePresets;
  selected?: TRange;
  elRef?: ElementRef<any>;
}

interface CalendarRangePickerDialogConfig {
  panelClass?: string | string[];
  hasBackdrop?: boolean;
  backdropClass?: string | string[];
  config?: CalendarRangeConfig;
}
export const DEFAULT_RANGES: TRangePresets = TIME_RANGE_DEFAULT_RANGES;
const DEFAULT_RANGE: TRange = {
  rangeType: 'month',
  name: 'time-range.preset.month',
  from: subMonths(new Date(), 1),
  to: endOfDay(new Date())
};
const DEFAULT_CONFIG: CalendarRangePickerDialogConfig = {
  hasBackdrop: true,
  backdropClass: ['calendar-range-dialog-dark-backdrop'],
  panelClass: 'calendar-range-dialog',
  config: {
    presets: DEFAULT_RANGES,
    selected: DEFAULT_RANGE
  }
};
export const CalendarRangeDefaultConfig = DEFAULT_CONFIG.config;

@Injectable()
export class CalendarRangePickerService {
  constructor(private injector: Injector, private overlay: Overlay) {}

  open(config: CalendarRangePickerDialogConfig = {}) {
    // Override default configuration
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };

    // Returns an OverlayRef which is a PortalHost
    const overlayRef = this.createOverlay(dialogConfig);

    // Instantiate remote control
    const dialogRef = new CalendarRangePickerOverlayRef(overlayRef);

    const overlayComponent = this.attachDialogContainer(overlayRef, dialogConfig, dialogRef);

    dialogRef.componentInstance = overlayComponent;

    overlayRef.backdropClick().subscribe(_ => dialogRef.close());

    return dialogRef;
  }

  private createOverlay(config: CalendarRangePickerDialogConfig) {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private attachDialogContainer(
    overlayRef: OverlayRef,
    config: CalendarRangePickerDialogConfig,
    dialogRef: CalendarRangePickerOverlayRef
  ) {
    const injector = this.createInjector(config, dialogRef);

    const containerPortal = new ComponentPortal(CalendarRangePickerComponent, null, injector);
    const containerRef: ComponentRef<CalendarRangePickerComponent> = overlayRef.attach(containerPortal);

    return containerRef.instance;
  }

  private createInjector(
    config: CalendarRangePickerDialogConfig,
    dialogRef: CalendarRangePickerOverlayRef
  ): PortalInjector {
    const injectionTokens = new WeakMap();

    injectionTokens.set(CalendarRangePickerOverlayRef, dialogRef);
    injectionTokens.set(CALENDAR_RANGE_DIALOG_DATA, config.config);

    return new PortalInjector(this.injector, injectionTokens);
  }

  private getOverlayConfig(config: CalendarRangePickerDialogConfig): OverlayConfig {
    const positionStrategy = !!config.config.elRef
      ? this.overlay
          .position()
          .flexibleConnectedTo(config.config.elRef)
          .withPositions([{ originX: 'center', originY: 'center', overlayX: 'center', overlayY: 'center' }])
      : this.overlay
          .position()
          .global()
          .centerHorizontally().centerVertically;

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      // @ts-ignore
      positionStrategy
    });

    return overlayConfig;
  }
}
